<template>
  <div class="w3-padding-32">
    <v-main>
      <v-snackbar  top v-model="snackbar" :timeout="3000" :color="color" class="text-center mt-3">
        <span class="white--text">{{message}}</span>
        <template v-slot:action="{attrs}">
          <v-btn  v-bind="attrs" @click="snackbar = !snackbar"  text class="white--text">
            <v-icon class="text-white">mdi-close</v-icon>
          </v-btn>
        </template>

      </v-snackbar>
      <v-img>
        <v-row class="px-2">
          <v-col cols="12" md="4" lg="4" sm="3">
            &nbsp;
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="6">
            <br>
            <v-card class="py-10 " elevation="4">


              <v-card-text class="py-6" style="line-height: 2.0!important;">
                <div class="text-center">
                  <v-btn icon v-if="getVerifiedTransaction.status"  color="success">
                    <v-icon class="w3-jumbo" x-large>mdi-check-circle</v-icon>
                  </v-btn>
                  <v-btn icon v-else  color="error">
                    <v-icon class="w3-jumbo" x-large>mdi-close-circle</v-icon>
                  </v-btn>
                </div>
                <br>
                <p class="text-center topic1 font-weight-bold" style="font-size:21px!important">
                 {{getVerifiedTransaction.status ? 'Transaction Successful' : 'Transaction Failed'}}
                </p>
                <p class="text-center topic1  " style="font-size:14px!important">
                  {{getVerifiedTransaction.message}}
                </p>

                <div class="text-center my-4">
                  <v-btn color="#AB005E" @click="generateReceipt" :loading="pdfLoading" v-if="getVerifiedTransaction.status" text>
                    <v-icon left>mdi-file-download-outline</v-icon>
                    <span class="text-capitalize">Download Receipt</span>


                  </v-btn>
                </div>


                <div class=" " style="width:80%!important; margin:0 auto">
                  <v-btn
                      v-if="!(!!getVerifiedTransaction.status) "
                      text
                      class="tryagain"
                      block
                      outlined
                      :to="{
                        name:'checkOut',
                        params:{access_token:getVerifiedTransaction.token, reference: getVerifiedTransaction.reference}
                      }"
                  >
                    <span class="text-capitalize">Try Again</span>
                    <v-icon right>mdi-reload</v-icon>

                  </v-btn>
                  <br   v-if="!(!!getVerifiedTransaction.status) ">
                  <v-btn
                      text
                      class="payButton"
                      block
                      :to="{
                        name:'paymentPage',
                        params:{
                          pagename:session
                        }
                      }"
                  >
                    <span class="text-capitalize">Return Home</span>

                  </v-btn>

                </div>

                <br>
                <p class="text-center mt-2">
                  <span class="topic1">Not sure? Send us an email</span>
                  <br>
                  <a style="color:#AB005E; text-decoration: underline!important;" :href="`mailto:${getCheckOutDetails.bemail}`">{{getCheckOutDetails.bemail}}</a>
                </p>

              </v-card-text>
              <vue-html2pdf
                  :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="false"
                  :paginate-elements-by-height="1400"
                  filename="receipt"
                  :pdf-quality="2"
                  :html-to-pdf-options="htmlToPdfOptions"
                  :manual-pagination="true"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="100%"
                  ref="html2Pdf"

              >
                <section slot="pdf-content">
                  <div style=" background-color: #f8f9fa!important; padding: 30px 20px!important">
                    <div style="width:100%; height:3px; background-color:#AB005E"></div>
                    <div style="background-color: white; padding:25px 10px;">

                      <div class="text-center" style="text-align: center!important; margin:0 auto;">

                        <v-avatar size="50">
                          <img src="@/assets/img/logo.png" alt="logo">
                        </v-avatar>

                      </div>
                      <br>



                      <div  style="text-align: center!important; margin:0 auto;">
                        <b style="font-size:16px!important;
                               line-height: 1.9;
                                font-weight: 600;

                                margin-top: 0;
                                margin-bottom: 1rem;
                                color: #67748e;">
                          Receipt from 1app Business
                        </b>
                        <br>


                        <div
                             class="topic text-center py-0"
                             style="
                               font-size:12px!important;
                               line-height: 1.9;
                                font-weight: 400;

                                margin-top: 0;
                                margin-bottom: 1rem;
                                color: #67748e;"
                        >
                          {{getTransactionDetails.customer_name}} made payment to
                          <b style="      text-transform: capitalize!important;">{{getTransactionDetails.business_name}}</b>
                        </div>
                      </div>
                      <div class="topic text-center py-1 mt-n4 "
                           style="font-size: 40px!important;
                               line-height: 1.625;
                                font-weight: 600;
                                text-align: center!important;
                                margin:0 auto;
                                margin-top: 0;
                                margin-bottom: 1rem;
                                color: #67748e;"
                      >
                        {{getTransactionDetails.currency}} {{format(getTransactionDetails.amount)}}

                      </div>
                      <v-divider class="mt-n3" style="background-color:#F5F5F5!important;"></v-divider>
                      <div>
                        <div class="text-center" style="text-align: center!important">
                          <span class="topic text-overline" style="text-transform: uppercase">payment details</span>
                        </div>
                        <v-list class="mt-3" style="background-color:white!important;padding:20px 5px!important; margin-top:12px!important">
                          <v-list-item class="mt-n6" style="margin-top:-1.2em!important;" v-for="item in transactionDetail" :key="item.title">
                            <v-list-item-content>
                              <span class="topic text-capitalize"
                                    style="
                                    line-height: 1.625;
                                    font-weight: 400;
                                    margin-top: 0;
                                    margin-bottom: 1rem;
                                    color: #67748e;
                                    text-transform: capitalize!important;
                                    font-size: 15px!important;"
                              >{{item.title}}</span>
                            </v-list-item-content>
                            <v-list-item-action>
                              <span class="topic text-capitalize"  style="font-size: 15px!important; text-transform: capitalize!important;">{{item.content}}</span>
                            </v-list-item-action>
                          </v-list-item>

                        </v-list>
                        <div class="text-center" style="text-align: center!important; margin-top:6px;">
                          <span class="topic text-overline" style="font-size: 14px!important;font-weight: 600!important;">{{getTransactionDetails.payment_time}}</span>
                        </div>
                        <v-divider  style="background-color:#F5F5F5!important;"></v-divider>
                        <div style="font-size: 14px!important;
                               line-height: 1.8;
                                text-align: center!important;
                                margin:0 auto;
                                margin-top: 0;
                                padding:5px 15px;
                                margin-bottom: 1rem;
                                color: #67748e;">
                        <span>
                          If you have questions or issues with this payment,
                          send us a mail at
                          <a style="color:blue" :href="`mailto:${getCheckOutDetails.bemail}`">{{getCheckOutDetails.bemail}}</a>

                        </span>
                          <br>
                          <b>Thank You.</b>
                        </div>

                      </div>
                    </div>

                  </div>
                </section>
              </vue-html2pdf>
            </v-card>
            <p class="text-center mt-3">
              <span class="topic1">Powered by </span> <v-avatar size="27">
              <img src="@/assets/img/logo.png" >
            </v-avatar>
            </p>
          </v-col>
        </v-row>
      </v-img>

    </v-main>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import {mapGetters} from "vuex";
import formatAmount from "@/hooks/formatAmount";
export default {
  name: "confirmTransaction",
  components:{VueHtml2pdf},
  data:()=>({
    snackbar: false,
    message:'',
    loader:false,
    color:'',
    pdfLoading:false,

  }),
  computed: {
    ...mapGetters([
      'getBusinessInfo',
      'getCheckOutDetails',
        'getVerifiedTransaction',
        'getTransactionDetails'
    ]),
    transactionDetail(){
      if(this.getTransactionDetails==='')return []
      return  [
        // {title:'Amount Paid', content:'₦'+formatAmount(this.getTransactionDetails.amount)},
        // {title:'Cus', content:'₦'+this.getTransactionDetails.fee},
        // {title:'Fee', content:'₦'+this.getTransactionDetails.fee},
        {title:'Method', content:this.getTransactionDetails.payment_channel},
        {title:'Reference', content:this.getTransactionDetails.reference},
        {title:'Status ', content:this.getTransactionDetails.statustext},
    /*    {title:'New Bal', content:'₦'+this.format(this.getTransactionDetails.newbal)},
        {title:'Type', content:this.getTransactionDetails.paytype},
        {title:this.$vuetify.breakpoint.smAndUp?'Reference' : 'Ref', content:this.getTransactionDetails.reference},
        {title:'Status', content:this.getTransactionDetails.tran_status},
        {title:'Description', content:this.getTransactionDetails.desc},*/
      ]
    },
    htmlToPdfOptions() {

      return {
        margin: [0.8,0.5],
        filename: `receipt-${Date.now()}.pdf`,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      };
    },

    session(){
      return sessionStorage.getItem('payment_link') || ''
    }
  },
  methods:{
    format: amount => formatAmount(amount),
    async generateReceipt () {
      this.pdfLoading=true
      setTimeout(async()=>{
        await this.$refs.html2Pdf.generatePdf()
        this.pdfLoading=false
      },2000)

    },
  },
  async mounted(){
    await this.$store.dispatch('fetchTransactionDetails', {
      reference:this.getVerifiedTransaction.reference
    })
  }

}
</script>

<style scoped>
.topic1{
  line-height: 1.625!important;
  font-weight: 400!important;
  font-size: 14px!important;
  margin-top: 0!important;
  margin-bottom: 1rem!important;
  color: #444!important;
  font-family: Poppins, sans-serif!important;
}
label{
  line-height: 1.625;
  font-weight: 400;
  font-size: 13px;
  color: #67748e;
  text-align: center!important;
  font-family: Poppins, sans-serif!important;
}
.payButton{
  background: #AB005E!important;
  border-color: #AB005E!important;
  color: #fff!important;
  width: 150px!important;
  padding: 0.6rem 1rem!important;
  font-size: 13px!important;
  opacity: 1!important;
  border-radius: 3px!important;
  font-weight: 400!important;
}
.tryagain{
  background: #fff!important;
  border-color: #AB005E!important;
  color: #AB005E!important;
  width: 150px!important;
  padding: 0.6rem 1rem!important;
  font-size: 13px!important;
  opacity: 1!important;
  border-radius: 3px!important;
  font-weight: 400!important;
}
</style>